<template>
  <form @submit.prevent="update" v-if="!!user">
    <div class="w-100 text-right">
      <button
        type="button"
        title="وضع التعديل"
        class="btn btn-sm btn-info nr-shadow"
        v-if="!editing"
        @click="changeMode(true)"
      >
        <i class="fas fa-pen"></i>
      </button>
      <button
        type="button"
        title="إلغاء"
        class="btn btn-sm btn-danger nr-shadow"
        v-if="editing"
        @click="changeMode(false)"
      >
        <i class="fa fa-times"></i>
      </button>
      <button
        type="button"
        title="حفظ"
        class="btn btn-sm btn-success nr-shadow"
        v-if="editing"
        @click="clickById('updateUserByAdmin')"
      >
        <i class="fa fa-check"></i>
      </button>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="row">
          <div class="col-lg-3">
            <label>الإسم</label>
          </div>
          <div class="col-lg-9">
            <label class="text-secondary" v-if="!editing">{{user.name}}</label>
            <FormInput
              v-else
              id="name"
              placeholder="الإسم الكامل"
              :value="user.name"
            />
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="row">
          <div class="col-lg-3">
            <label>رقم الملف</label>
          </div>
          <div class="col-lg-9">
            <label class="text-secondary" v-if="!editing">{{ user.file_no }}</label>
            <FormInput
              v-else
              id="file_no"
              placeholder="رقم الملف"
              :value="user.file_no"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="row">
          <div class="col-lg-3"><label>الايميل</label></div>
          <div class="col-lg-9">
            <label class="text-secondary" v-if="!editing">{{user.email }}</label>
            <FormInput
              v-else
              id="email"
              placeholder="البريد الإلكتروني"
              :value="user.email"
            />
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="row">
          <div class="col-lg-3"><label>إسم الدخول</label></div>
          <div class="col-lg-9">
            <label class="text-secondary" v-if="!editing">{{user.username}}</label>
            <FormInput
              v-else
              id="username"
              placeholder="إسم المستخدم"
              :value="user.username"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="row">
          <div class="col-lg-3"><label>الجنسية</label></div>
          <div class="col-lg-9">
            <label class="text-secondary" v-if="!editing">{{user.nationality }}</label>
            <bootstrap-select
              v-else
              id="nationality"
              @changed="setNationality"
              :options="nationalities"
              :selected="user.nationality"
            ></bootstrap-select>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="row">
          <div class="col-lg-3">
            <label>{{ nationalNumDesc }}</label>
          </div>
          <div class="col-lg-9">
            <label class="text-secondary" v-if="!editing">{{user.national_number }}</label>
            <FormInput
              :type="nationalityType"
              v-else
              id="national_number"
              :placeholder="nationalNumDesc"
              :value="user.national_number"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="row">
          <div class="col-lg-3"><label>المحافظة</label></div>
          <div class="col-lg-9">
            <label class="text-secondary" v-if="!editing">{{user.city }}</label>
            <bootstrap-select
              v-else
              @changed="setAreas"
              :options="cities"
              id="city_id"
            ></bootstrap-select>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="row">
          <div class="col-lg-3"><label>المنطقة</label></div>
          <div class="col-lg-9">
            <label class="text-secondary" v-if="!editing">{{user.area }}</label>
            <bootstrap-select
              v-else
              :options="areas"
              id="area_id"
              :selected="user.area_id"
            ></bootstrap-select>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="row">
          <div class="col-lg-3"><label>الشارع</label></div>
          <div class="col-lg-9">
            <label class="text-secondary" v-if="!editing">{{user.street}}</label>
            <FormInput
              v-else
              id="street"
              placeholder="الشارع"
              :value="user.street"
            />
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="row">
          <div class="col-lg-3"><label>رقم البناية</label></div>
          <div class="col-lg-9">
            <label class="text-secondary" v-if="!editing">{{user.building}}</label>
            <FormInput
              v-else
              id="building"
              placeholder="رقم البناية"
              :value="user.building"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="row">
          <div class="col-lg-3">
            <label class="link"
              ><abbr :title="auctionsLimit">المزادات</abbr></label
            >
          </div>
          <div class="col-lg-9">
            <label class="text-secondary" v-if="!editing">{{user.auctions_count}}</label>
            <FormInput
              type="number"
              v-else
              id="auctions_count"
              :value="user.auctions_count"
              placeholder="الحد الأقصى للمزادات"
            />
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="row">
          <div class="col-lg-3"><label>كلمة المرور</label></div>
          <div class="col-lg-9">
            <label class="text-secondary" v-if="!editing">********</label>
            <FormInput
              type="password"
              v-else
              id="password"
              placeholder="********"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="row">
          <div class="col-lg-3"><label>تاريخ الميلاد</label></div>
          <div class="col-lg-9">
            <label class="text-secondary" v-if="!editing">
              {{ user.year }} / {{ user.month }} / {{ user.day }}
            </label>
            <div v-else>
              <bootstrap-select
                title="اليوم"
                id="day"
                :options="days"
                :selected="user.day"
              ></bootstrap-select>
              <bootstrap-select
                title="الشهر"
                id="month"
                :options="months"
                :selected="user.month"
              ></bootstrap-select>
              <bootstrap-select
                title="السنة"
                id="year"
                :options="years"
                :selected="user.year"
              ></bootstrap-select>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6" v-if="!!createdAt">
        <div class="row">
          <div class="col-lg-3"><label>تاريخ إنشاء الحساب</label></div>
          <div class="col-lg-9">
            <label class="text-secondary">
              {{ createdAt }}
            </label>
          </div>
        </div>
      </div>
    </div>
    <button type="submit" hidden id="updateUserByAdmin">حفظ</button>
  </form>
</template>

<script>
import { mapGetters } from "vuex";
import arrays from "@/common/arrays";
import random from "@/common/random";
import user_mixin from "@/mixins/user_mixin";
import FormInput from "@/components/Forms/FormInput";
import BootstrapSelect from "@/components/Forms/BootstrapSelect";
import date from "@/common/dateMethods";

export default {
  components: { BootstrapSelect, FormInput },
  mixins: [user_mixin],
  data() {
    return {
      user: "",
      editing: false,
    };
  },
  computed: {
    ...mapGetters(["cities", "nationalities", "areas", "shouldReload"]),
    days() {
      return arrays.generateArray(31);
    },
    months() {
      return arrays.generateArray(12);
    },
    years() {
      return random.list(new Date().getFullYear() - 17);
    },
    userId() {
      return !!this.$route ? this.$route.params.user : "";
    },
    auctionsLimit() {
      return messages.auctionsLimit;
    },
    createdAt() {
      return !this.user || !this.user.created_at
        ? ""
        : date.withoutTime(this.user.created_at, true);
    },
  },
  methods: {
    async setUserInfo() {
      if (!this.userId) {
        return;
      }

      const response = await http.send(`users/${this.userId}`, {
        _method: "GET",
      });
      if (http.responseAccepted(response)) {
        this.setUserData(response.data.user);
      }
    },
    async update(e) {
      const data = new FormData(e.target);
      data.set("_method", "PUT");

      const response = await http.send(`users/${this.userId}`, data);
      if (http.responseAccepted(response)) {
        this.setUserData(response.data.user);
        this.editing = false;
        http.popupMessage("success", messages.updated);
      }
    },
    setUserData(data) {
      if (!data) {
        return;
      }

      this.user = data;
    },
    changeMode(mode) {
      this.editing = mode;
    },
  },
  async created() {
    await this.setUserInfo();
  },
};
</script>

<style scoped>
label {
  max-width: 100% !important;
  padding: 0;
  margin: 0;
}
</style>
